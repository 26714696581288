import { useContext, useState, MouseEvent, lazy } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import LoginForm from '../components/forms/LoginForm'
import ForgotPassForm from '../components/forms/ForgotPassForm'
import { LanguageContext } from '../../context/languageContext'
import { useVersion } from '../../context/appVersionContext'

const LanguageSelectorModal = lazy(() => import('../components/modals/languageSelectorModal'));


function Login() {
	const { appVersion } = useVersion()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const [showed, setShown] = useState({
		loginForm: true,
		forgotPassForm: false,
	})

	const handleClick = (event: MouseEvent<HTMLElement>): void => {
		switch (event.currentTarget.id) {
			case 'forgotPass':
				setShown({
					loginForm: false,
					forgotPassForm: true,
				})
				break

			case 'close':
				setShown({
					loginForm: true,
					forgotPassForm: false,
				})
				break
			default:
				break
		}
	}

	return (
		<>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '5%',
					right: '5%',
					paddingX: 2,
					paddingY: 1,
				}}
			>
				<LanguageSelectorModal />
			</Box>


			<Grid item xs={12}>
				<Grid container justifyContent="center">
					{showed?.loginForm && <LoginForm onForgotPass={handleClick} />}

					{showed?.forgotPassForm && <ForgotPassForm onClose={handleClick} />}
				</Grid>
				<Box
					sx={{
						position: {
							xs: 'relative',
							sm: 'relative',
							md: 'absolute',
							lg: 'absolute',
							xl: 'absolute',
						},
						left: { xs: 0, sm: 0, md: 16, lg: 16, xl: 16 },
						bottom: { xs: 0, sm: 0, md: 16, lg: 16, xl: 16 },
						top: { xs: 30, sm: 30, md: 16, lg: 16, xl: 16 },
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'center',
							md: 'flex-start',
							lg: 'flex-start',
							xl: 'flex-start',
						},
						alignItems: {
							xs: 'center',
							sm: 'center',
							md: 'flex-end',
							lg: 'flex-end',
							xl: 'flex-end',
						},
						width: { xs: '100%', sm: '100%', md: 'auto', lg: 'auto', xl: 'auto' },
						marginLeft: {
							xs: '0px',
							sm: '0px',
							md: '50px',
							lg: '20px',
							xl: '20px',
						},
						marginTop: { xs: '5px', sm: '5px' },
					}}
				>
					<Typography variant="caption" sx={{ color: 'white' }}>
						{process.env.REACT_APP_LOGIN_API_VERSION || '1.0.0'}
					</Typography>
				</Box>
			</Grid>
		</>
	)
}

export default Login
